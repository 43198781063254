<template>
	<div class="components">
		<div class="headrs">
			<div class="hlef">
				<div class="imglo">
					<img class="img" src="../../public/image/logo.png" alt="" />
				</div>
			</div>
			<div class="headezi">普华能源托管收费平台V1.0</div>
			<div class="headtouim">
				<div class="userName" @mouseover="select">{{name}}</div>
				<div v-if="temp" class="selectList" @mouseleave="mouseLeave">
					<div class="selected" @click="modifyResu()">
						<img class="myImg1" src="../../public/image/doResourse.png" alt="" />
						<span class="detailText">完善资料</span>
					</div>
					<div class="selected selecteds" @click="modifyPassword()" >
						<img class="myImg1" src="../../public/image/modifyPw.png" alt="" />
						<span class="detailText">修改密码</span>
					</div>
					<div class="selected selecteds1" @click="reportRepair()" >
						<img class="myImg1" src="../../public/image/modify.png" alt="" />
						<span class="detailText">故障报修</span>
					</div>
					<div class="selected selecteds2" @click="out()" >
						<img class="myImg1" src="../../public/image/logOut.png" alt="" />
						<span class="detailText">退出登录</span>
					</div>
				</div>
				<img class="myImg" src="../../public/image/express.png" alt="" />
			</div>
			<Modal
				v-model="modify"
				title="修改密码"
				class="modalHeard"
				@on-cancel="cancel"
				>
				<i-form ref='form' :rules="rules" :model="form" :label-width="100">
					<Form-item label="旧密码：" prop="oldPassword">
						<i-input v-model="form.oldPassword" type="password" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="新密码：" prop="password">
						<i-input v-model="form.password" type="password" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="确认密码：" prop="confirmPassword">
						<i-input v-model="form.confirmPassword" type="password" placeholder="请输入"></i-input>
					</Form-item>
				</i-form>
				<div slot="footer" class="footer">
					<Button type="success" class="sure" @click="handleSubmit()">确定</Button>
					<Button type="primary" class="cancel" @click="cancel()">取消</Button>
				</div>
			</Modal>
			<Modal
				v-model="modifyRes"
				title="完善资料"
				class="modalHeard myModal"
				@on-cancel="cancel1"
				>
				<Form ref='form1' :rules="rules1" :model="form1" :label-width="140" inline>
					<Form-item label="户号：">
						<i-input v-model="form1.no" disabled placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="一级报价天数：">
						<i-input v-model="form1.firstAlarm" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="购方企业名称：">
						<i-input v-model="form1.name" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="二级报价天数：">
						<i-input v-model="form1.secondAlarm" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="购方纳税人识别号：">
						<i-input v-model="form1.taxTegNo" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="三级报价天数：">
						<i-input v-model="form1.thirdAlarm" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="购方地址：">
						<i-input v-model="form1.address" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="">
					</Form-item>
					<Form-item label="购方电话：">
						<i-input v-model="form1.phone" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="">
					</Form-item>
					<Form-item label="购方开户行：">
						<i-input v-model="form1.bank" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="">
					</Form-item>
					<Form-item label="购方账号：">
						<i-input v-model="form1.bankAccount" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="">
					</Form-item>
					<Form-item label="备注：">
						<i-input v-model="form1.memo" placeholder="请输入"></i-input>
					</Form-item>
				</Form>
				<div slot="footer" class="footer">
					<Button type="success" class="sure" @click="handleSubmit1()">确定</Button>
					<Button type="primary" class="cancel" @click="cancel1()">取消</Button>
				</div>
			</Modal>
			<Modal
				v-model="repairModal"
				title="故障报修"
				class="modalHeard modalRepair"
				@on-cancel="cancel2"
				>
				<i-form ref='form2' :rules="rules" :model="form2" :label-width="100">
					<Form-item label="报修内容：" >
						<i-input v-model="form2.content" type="textarea" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item label="联系方式：">
						<i-input v-model="form2.mobile" placeholder="请输入"></i-input>
					</Form-item>
					<Form-item >
						<div class="demo-upload-list" v-for="(item,i) in defaultList" :key="i">
							<template>
								<img :src="item.url">
								<div class="demo-upload-list-cover">
									<Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
								</div>
							</template>
						</div>
						<Upload
							ref="upload"
							:show-upload-list="false"
							:default-file-list="defaultList"
							:on-success="handleSuccess"
							:format="['jpg','jpeg','png','JPG','JPEG','PNG']"
							:max-size="10240"
							:on-exceeded-size="handleMaxSize"
							type="drag"
							:headers = "headers"
							:action="src"
							class="myUpload"
							>
							<div class="myUploadDiv">
								<Icon type="ios-camera" size="30"></Icon>
							</div>
						</Upload>
					</Form-item>
				</i-form>
				<div slot="footer" class="footer">
					<Button type="success" class="sure" @click="handleSubmit2()">提交</Button>
					<Button type="primary" class="cancel" @click="cancel2()">取消</Button>
				</div>
			</Modal>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import { mapGetters,mapActions } from 'vuex'
	import baseURL from '@/utils/env.js';
	import url from '../utils/api.js';
	import bus from "@/utils/bus.js";
	export default {
		name: "v-header",
		data() {
			return {
				name: this.common.get("username"),
				defaultList:[],
				src:baseURL + url.UPLOAD_IMG,
				temp:false,
				modify:false,
				modifyRes:false,
				repairModal:false,
				headers:{
					"Authorization": 'bearer '+this.common.get("token")
				},
				form:{
					oldPassword:'',
					password:'',
					confirmPassword:''
				},
				form1:{},
				form2:{
					content:'',
					mobile:'',
					photo:''
				},
				rules:{
                    oldPassword:[
                        { required: true, message: '旧密码不能为空', trigger: 'blur' }
					],
					password:[
						{ required: true, message: '新密码不能为空', trigger: 'blur' }
					],
					confirmPassword:[
						{ required: true, message: '确认密码不能为空', trigger: 'blur' }
					]
                },
				rules1:{

				}
			}
		},
		computed: {
			...mapGetters([
				'isLogin'
            ]),
		},
		mounted: function() {
			if(!this.$store.state.isLogin){
				this.isLogin
			}
			this.actionTemp(true)
			// this.name = this.$store.state.myInfo.userName
		},
		methods: {
			
			...mapActions([
                'actionOut',
				'actionTemp'
            ]),
			handleRemove (file) {
                this.defaultList.map((el,index)=>{
					if(el == file){
						this.defaultList.splice(index,1)
					}
				})
            },
			handleSubmit2(){
				let src = ''
				this.defaultList.map(el=>{
					src = src?src+';'+el.response:el.response
				})
				this.form2.photo = src
				this.$post(url.REPORT_REPAIR,this.form2).then(()=>{
					this.$Message.success('故障报修成功！');
					this.cancel2()
					bus.$emit("getNewList");
				}).catch(error=>{
					this.common.tokenIsTrue(this,error);
				})
			},
			handleSuccess (res, file) {
                this.defaultList.push(file)
            },
            handleMaxSize () {
                this.$Notice.warning({
                    title: '提示',
                    desc: '图片大小不能超过10M'
                });
            },
			cancel2(){
				this.repairModal = false
				this.form2.photo = ''
				this.form2.content = ''
				this.form2.mobile = ''
				this.defaultList = []
			},	
			reportRepair(){
				this.repairModal = true
			},
			modifyResu(){
				this.$get(url.GET_DETAIL).then(res=>{
					this.form1 = res
				}).catch(error=>{
					this.common.tokenIsTrue(this,error);
				})
				this.modifyRes = true
			},
			modifyPassword(){
				this.modify = true
			},
			cancel(){
				this.modify = false;
				this.form.oldPassword = '';
				this.form.password = '';
				this.form.confirmPassword = '';
			},
			cancel1(){
				this.modifyRes = false;
			},
			handleSubmit1(){
				this.modifyRes = false;
				this.$post(url.USER_MODIFY,this.form1).then(()=>{
					this.$Message.success('资料修改成功！');
					this.form1 = {}
				}).catch(error=>{
					this.common.tokenIsTrue(this,error);
				})
			},
			handleSubmit(){
				if(this.form.password && this.form.password === this.form.oldPassword){
					this.$Message.warning('新密码不能与旧密码一致!');
					return
				}
				if(this.form.confirmPassword && this.form.confirmPassword !== this.form.password) {
					this.$Message.warning('新密码与确认密码不一致!');
					return
				}
				let changePasswordReq = Object.assign({},this.form);
				this.$post(url.USER_MODIFY_PASSWORD,changePasswordReq).then(()=>{
					this.modify = false;
                    this.$Message.success('修改成功，正跳转登录页重新登录！');
					setTimeout(() => {
						this.$router.push({
							path: '/'
						});
					}, 1500)
                }).catch(error => {
                    this.common.tokenIsTrue(this,error);
                })
			},
			select(){
                this.temp = this.temp?false:true
			},
			mouseLeave(){
				this.temp = false
			},
			out(){
				this.common.delete('token')
				this.common.delete('reToken')
				this.common.delete('expiresin')
				this.common.delete('username');
				setTimeout(() => {
					this.actionOut()
					this.$router.push({
						path: '/'
					});
				}, 1000)
			}
		}

	}
</script>
<style lang="less">
	.modalHeard>.ivu-modal-wrap>.ivu-modal>.ivu-modal-content{
		.ivu-modal-close {
			top: 5px;
			.ivu-icon {
				color: #fff;
				font-size: 30px;
			}
		}
		.ivu-modal-header {
			height: 40px;
			background-color: #0ccec3;
			border-radius: 4px 4px 0px 0px;
			padding: 0;
			.ivu-modal-header-inner {
				color: #fff;
				font-family: MicrosoftYaHei-Bold;
				font-size: 14px;
				font-stretch: normal;
				line-height: 40px;
				height: 40px;
				letter-spacing: 0px;
				color: #ffffff;
				margin-left: 10px;
			}
		}
	}
	.modalRepair>.ivu-modal-wrap>.ivu-modal{
		width:580px !important;
	}
	.myModal>.ivu-modal-wrap>.ivu-modal{
		width:840px !important;
		.ivu-modal-content>.ivu-modal-body>.ivu-form>.ivu-form-item>.ivu-form-item-content>.ivu-input-wrapper>.ivu-input{
			width: 250px;
		}
	}
</style>
<style scoped lang="less">
	.components {
		margin: 0px 0px 0px 0px;
		width: 100%;
		height: 100%;
		background: #f0f3f8;
		position: fixed;
	}
	.headrs {
		background-image: linear-gradient(90deg,
			#3c32ad 0%,
			#582cdd 100%);
		width: 100%;
		height: 60px;
		display: flex;
		justify-content:flex-start;
	}
	.hlef {
		background-image: linear-gradient(90deg,
			#3c32ad 0%,
			#582cdd 100%);
		width: 240px;
		height: 60px;
		display: flex;
		align-items:center;
		justify-content:center;
	}
	.imglo {
		width: 129px;
		height: 32px;
	}
	.headezi{
		width:1440px;
		height:100%;
		font-family: SourceHanSansCN-Regular;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 60px;
		letter-spacing: 0px;
		color: #ffffff;
		text-align:left;
		padding-left:30px
	}
	.headtouim{
		width: 240px;
		display: flex;
		align-items:center;
	}
	.userName{
		font-family: ArialMT;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		margin-left:20px;
		line-height: 60px;
		letter-spacing: 0px;
		color: #c4b4f9;
		cursor:pointer;
	}
	.userLine{
		width: 1px;
		height: 20px;
		margin-left:19px;
		background-color: #7853e8;
	}
	.outImg{
		width: 21px;
		height: 20px;
		margin-left:28px;
		cursor: pointer;
	}
	.img{
		width: 100%;
		height: 100%;
	}
	.selectList{
		width: 151px;
		line-height: 40px;
		height: 160px;
		box-shadow: 0px 5px 10px 0px rgba(30, 19, 15, 0.3);
		border-radius: 0px 0px 4px 4px;
		background-color: #fff;
		position: absolute;
		top: 60px;
		z-index: 999;
		right: 92px;
		cursor: pointer;
	}
	.selected{
        width: 100%;
        height: 40px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 0px;
        position: absolute;
        color: #44475d;
		display:flex;
		align-items:center;
        cursor:pointer;
    }
	.selected:hover{
		background-color: #f0f3f8;
		color: #0ccec3;
	}
    .selecteds{
		top:40px;
	}
	.selecteds1{
		top:80px;
	}
	.selecteds2{
		top:120px;
	}
	.detailText{
		margin-left:16px;
	}
	.myImg{
		width:6px;
		height:7px;
		margin-left:8px;
		margin-top:5px;
	}
	.myImg1{
		width:15px;
		height:15px;
		margin-left:24px;
	}
	.sure{
		width: 80px;
		height: 32px;
		background-color: #0ccec3;
		border-radius: 4px;
		border-color:#0ccec3;
	}
	.cancel{
		width: 80px;
		height: 32px;
		background-color: #abb2c0;
		border-radius: 4px;
		border-color:#abb2c0;
	}
	.footer{
		text-align: center;
	}
	.myUpload {
		display: inline-block;
		width: 100px;
	}

	.myUploadDiv {
		width: 100px;
		height: 76px;
		line-height: 76px;
	}

	.demo-upload-list {
		display: inline-block;
		width: 100px;
		height: 76px;
		text-align: center;
		line-height: 76px;
		border: 1px solid transparent;
		border-radius: 4px;
		overflow: hidden;
		background: #fff;
		position: relative;
		box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
		margin-right: 9px;
	}

	.demo-upload-list img {
		width: 100%;
		height: 100%;
	}

	.demo-upload-list-cover {
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, .6);
	}

	.demo-upload-list:hover .demo-upload-list-cover {
		display: block;
	}

	.demo-upload-list-cover i {
		color: #fff;
		font-size: 30px;
		cursor: pointer;
		margin: 0 20px;
    }
</style>
